const { PUBLIC_URL } = process.env;
export const servicesList = [
  {
    title: "Social Media",
    desc: "Engage and connect with your audience on various platforms to build meaningful relationships and boost brand visibility.",
    icon: `${PUBLIC_URL}/img/services/services_icon_01.svg`,
    img: `${PUBLIC_URL}/img/services/Banners_Social Media.webp`,
  },
  {
    title: "SEO",
    desc: "Improve your website's search engine rankings and increase organic traffic through strategic optimization techniques.",
    icon: `${PUBLIC_URL}/img/services/services_icon_02.svg`,
    img: `${PUBLIC_URL}/img/services/Banners_SEO.webp`,
  },
  {
    title: "Paid Media",
    desc: "Amplify your message and reach your target audience through targeted advertising across various channels.",
    icon: `${PUBLIC_URL}/img/services/services_icon_03.svg`,
    img: `${PUBLIC_URL}/img/services/Banners_Paid Media.webp`,
  },
  {
    title: "Paid Social",
    desc: "Maximize your reach and engagement by leveraging paid advertising on social media platforms to drive conversions and achieve your business goals.",
    icon: `${PUBLIC_URL}/img/services/services_icon_04.svg`,
    img: `${PUBLIC_URL}/img/services/Banners_Paid social.webp`,
  },
  {
    title: "Branding",
    desc: "Craft a compelling brand identity and story that resonates with your audience, fostering trust, loyalty, and recognition.",
    icon: `${PUBLIC_URL}/img/services/services_icon_05.svg`,
    img: `${PUBLIC_URL}/img/services/Banners_Branding.webp`,
  },
  {
    title: "Web Design",
    desc: "Make a strong first impression with a stunning website that is both visually appealing and user-friendly.",
    icon: `${PUBLIC_URL}/img/services/services_icon_06.svg`,
    img: `${PUBLIC_URL}/img/services/Banners_Web Design.webp`,
  },
  {
    title: "Website Development",
    desc: "Transform your digital presence with custom-built websites that are optimized for functionality.",
    icon: `${PUBLIC_URL}/img/services/services_icon_07.svg`,
    img: `${PUBLIC_URL}/img/services/Banners_Website development.webp`,
  },
  {
    title: "Mobile App Design",
    desc: "Engage users on the go with visually striking mobile app designs that enhance the user experience.",
    icon: `${PUBLIC_URL}/img/services/services_icon_08.svg`,
    img: `${PUBLIC_URL}/img/services/Banners_Mobile app design.webp`,
  },
];
